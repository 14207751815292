import gql from "graphql-tag";
const data = `data {
    applicationFeedbackUniqueId
    applicationFeedbackInterviewDate
    applicationFeedbackDescription
    applicationFeedbackCategory
    applicationFeedbackCreatedDate
    hasReprayed
     hasConfirmedArrival
     hasConfirmedForInterview
     replay {
        applicationFeedbackReplayUniqueId
        applicationFeedbackReplayDescription
        replayUser {
          id
          profileUniqueId
          username
          firstName
          middleName
          lastName
          profilePicture
          gender
          institution
          role
          dateJoined
        }
    }

}`
const response = `response {
    status
    code
    message
    id
}`

export const  getApplicationFeedback = gql`query GetApplicationFeedback($applicationRequestUniqueId: String!) {
    getApplicationFeedback(applicationRequestUniqueId: $applicationRequestUniqueId) {
      ${response}
      ${data}
    }
}
`;
export const createApplicationFeedback = gql`mutation CreateApplicationFeedback($input: ApplicationFeedbackInputObject) {
    createApplicationFeedback(input: $input) {
        ${response}
        ${data}
    }
}`;
export const createApplicationFeedbackReplay = gql`mutation CreateApplicationFeedbackReplayMutation($input: FeedbackReplayInputObject!) {
  createApplicationFeedbackReplayMutation(input: $input){
        ${response}
        ${data}
    }
}`;
