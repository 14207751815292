import alumniRouter from "@/components/memberships/Alumni/alumniRouter";
import diasporaRouter from "@/components/memberships/Diaspora/diasporaRouter";

export default [
  {
    path: "/research",
    name: "ResearchMain",
    component: () => import("@/components/research/ResearchMain.vue"),
    meta: {
      permissions: ["can_view_research"],
    },
    children: [
      {
        path: "index",
        name: "Research Index",
        component: () => import("./Index.vue"),

        meta: {
          permissions: [
              "can_view_research"
          ],
        },
      },
      // {
      //     name: "Diaspora",
      //     path: "diaspora-panel",
      //     component: () =>
      //         import("@/components/memberships/Diaspora/DiasporaMain.vue"),
      //     meta: {
      //         permissions: [],
      //     },
      //     children: diasporaRouter // Nest diasporaRouter routes here

      // },
      {
        name: "Research Publications",
        path: "/research-publications",
        component: () => import("@/components/research/research_publications/Index.vue"),

        meta: {
          permissions: ["can_view_research"],
        },
      },
      {
        name: "My Publications",
        path: "/my-publications",
        component: () => import("@/components/research/my_publications/Index.vue"),

        meta: {
          permissions: ["can_view_research"],
        },
      },
      {
        path: "my-publication-preview/:publicationUniqueId",
        name: "MyPublication Preview",
        component: () => import("@/components/research/my_publications/PublicationPreview.vue"),
        meta: {
          permissions: ["can_view_research"]
        },
        props: true
      },
      {
        name: "Research Proposals",
        path: "/research-proposals",
        component: () => import("@/components/research/research_proposals/Index.vue"),

        meta: {
          permissions: ["can_view_research"],
        },
      },
      {
        path: "research-publication-preview/:publicationUniqueId",
        name: "ResearchPublication Preview",
        component: () => import("@/components/research/research_publications/PublicationPreview.vue"),
        meta: {
          permissions: ["can_view_research"]
        },
        props: true
      },
      {
        path: "my-proposals",
        name: "my proposals",
        component: () => import("@/components/research/my_reseach_proposals/myProposalsPreview.vue"),
        meta: {
          permissions: ["can_view_research"]
        },
        props: true
      },
      {
        path: "my-proposals/:uniqueId",
        name: "my proposals view",
        component: () => import("@/components/research/my_reseach_proposals/myProposalView.vue"),
        meta: {
          permissions: ["can_view_researchers_publications"]
        },
        props: true
      },

      {
        path: "research-proposal-preview/:proposalUniqueId",
        name: "ResearchProposal Preview",
        component: () => import("@/components/research/research_proposals/ProposalPreview.vue"),
        meta: {
          permissions: ["can_view_researchers_publications"]
        },
        props: true
      },
    ],
  },
];

