import AlumniRequestsRouter from "@/components/memberships/Alumni/AlumniRequests/AlumniRequestsRouter";
import myAlumniRouter from "@/components/memberships/Alumni/MyAlumniDetails/myAlumniRouter";

export default [
    {
        name: "AlumniMain",
        path: "/alumni-panel",
        component: () =>
            import("@/components/memberships/Alumni/AlumniMain.vue"),
        meta: {
            permissions: [
                "can_view_alumni"
            ],
        },

        children: [
            {
                name: "Alumni Index",
                path: "index",
                component: () => import("./Index.vue"),
                meta: {
                    permissions: [
                        "can_view_alumni"
                    ],
                },
            },
            ...AlumniRequestsRouter,
            ...myAlumniRouter
        ],
    },
];
